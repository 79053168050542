import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, SkeletonLoader, Text, mq } from '@clutter/clean';

import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { Price } from '@root/components/checkout/product_pages/subcomponents/fees/highlighted_price';
import { PLANS } from '@root/constants/pricing';

import { Currency } from '@shared/currency';
import { Fallback } from '@shared/fallback';

import { PricingSummary } from '@utils/hooks/pricing/use_pricing_summary';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { useLastDefined } from '@utils/hooks/use_last_defined';

import storageUnit from '@images/illustrations/self_storage_unit.svg';

const SummaryContainer = styled.div`
  display: flex;
  background: ${COLORS.cloud};
  border: 1px solid ${COLORS.grayBorder};
  ${mq({
    flexDirection: ['column', null, null, 'row'],
    padding: ['12px', '24px'],
  })}
`;

const PriceContainer = styled.div`
  flex-shrink: 0;
  border-color: ${COLORS.grayBorder};
  ${mq({
    borderStyle: ['none', null, null, 'none none none solid'],
    margin: ['0 0 0 72px', null, null, 'initial'],
    maxWidth: ['initial', null, null, '128px'],
    minWidth: ['initial', null, null, '120px'],
    textAlign: ['left', null, null, 'center'],
  })};
`;

const MONTHLY_SUFFIX = '/mo';

const Headline: React.FC<{
  summary?: PricingSummary;
}> = ({ summary }) => {
  const pricingSummary = useLastDefined(summary);

  if (!pricingSummary)
    return (
      <Text.Caption color={COLORS.tealDark}>
        Your Smart Storage Plan
      </Text.Caption>
    );

  const {
    monthlyAmount,
    moverMonthlyAmount,
    onboardingFeeAmount,
    maxOnboardingFeeAmount,
  } = pricingSummary;

  const monthlySavings = moverMonthlyAmount - monthlyAmount;
  const onboardingSavings = maxOnboardingFeeAmount - onboardingFeeAmount;

  if (!onboardingSavings && !monthlySavings)
    return (
      <Text.Caption color={COLORS.tealDark}>
        Your Smart Storage Plan
      </Text.Caption>
    );

  const savingsItems: React.ReactNode[] = [];

  if (monthlySavings)
    savingsItems.push(
      <b key="monthly">
        <Currency amount={monthlySavings} />
        {MONTHLY_SUFFIX}
      </b>,
    );

  if (onboardingSavings) {
    savingsItems.push(
      <React.Fragment key="pickupFee">
        <b>
          <Currency amount={onboardingSavings} />
        </b>{' '}
        <span style={{ whiteSpace: 'nowrap' }}>on pickup</span>
      </React.Fragment>,
    );
  }

  return (
    <Text.Caption color={COLORS.tealDark}>
      <Fallback
        delayMs={0}
        loader={
          <SkeletonLoader width="240px" height="17.5px" margin="0 auto" />
        }
        value={summary}
      >
        You’re saving {savingsItems.reduce((acc, cur) => [acc, ' and ', cur])}
      </Fallback>
    </Text.Caption>
  );
};

export const SavingsSummary = () => {
  const {
    pricingSummary: summary,
    defaultPricingSummary,
    flowState: {
      values: { planSize },
    },
  } = useSharedCheckoutContext();
  const { isMobile } = useBreakpoints();

  const pricingSummary = useLastDefined(summary);

  const HeadingText = isMobile ? Text.Callout : Text.Body;
  const DescriptionText = isMobile ? Text.Caption : Text.Callout;

  return (
    <Box borderRadius="4px" background={COLORS.cloud} overflow="hidden">
      <Box
        borderRadius="4px 4px 0 0"
        padding="8px 24px"
        background={COLORS.dust}
        textAlign="center"
      >
        <Headline summary={summary} />
      </Box>
      <SummaryContainer>
        <Box.Flex>
          <img
            width="60"
            src={storageUnit}
            alt="A storage unit containing boxes"
          />
          <Box.FlexItem margin="0 16px">
            <HeadingText color={COLORS.panther}>
              <b>{planSize ? PLANS[planSize].label : 'Smart Storage'}</b>
            </HeadingText>
            <DescriptionText color={COLORS.storm}>
              Your Smart Storage space has temperature control, 24/7 security,
              and an online photo inventory.
            </DescriptionText>
          </Box.FlexItem>
        </Box.Flex>
        <PriceContainer>
          <Box.Flex
            flexDirection="column"
            justifyContent={['end', null, null, 'center']}
            textAlign={['left', null, null, 'center']}
            height="100%"
          >
            <Box.Flex
              flexDirection={['row', null, null, 'column']}
              alignItems={['center', null, null, 'initial']}
              margin={['12px 0 0', null, null, 'initial']}
            >
              {pricingSummary ? (
                <Price
                  amount={pricingSummary.monthlyAmount}
                  maxAmount={pricingSummary.moverMonthlyAmount}
                  suffix={MONTHLY_SUFFIX}
                  isMobile={isMobile}
                  loading={!summary}
                />
              ) : (
                <Price
                  amount={defaultPricingSummary?.monthlyAmount || 0}
                  suffix={MONTHLY_SUFFIX}
                  isMobile={isMobile}
                  displayStartingAt
                  loading={!defaultPricingSummary || !!planSize}
                />
              )}
            </Box.Flex>
          </Box.Flex>
        </PriceContainer>
      </SummaryContainer>
    </Box>
  );
};
