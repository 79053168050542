import React from 'react';
import { animated, useTransition } from 'react-spring';

import styled from '@emotion/styled';

import { BREAKPOINTS, Box, mq } from '@clutter/clean';

import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { PlanName } from '@root/resources/types/plan_name';

import { useBreakpoint } from '@utils/hooks';

import fiveByFive from '@images/illustrations/unit_sizes/5x5_small.png';
import fiveByTen from '@images/illustrations/unit_sizes/5x10_small.png';
import tenByTen from '@images/illustrations/unit_sizes/10x10_small.png';
import tenByFifteen from '@images/illustrations/unit_sizes/10x15_small.png';
import tenByTwenty from '@images/illustrations/unit_sizes/10x20_small.png';
import tenByTwentyFive from '@images/illustrations/unit_sizes/10x25_small.png';
import tenByThirty from '@images/illustrations/unit_sizes/10x30_small.png';
import tenByForty from '@images/illustrations/unit_sizes/10x40_small.png';
import tenByFifty from '@images/illustrations/unit_sizes/10x50_small.png';
import item from '@images/illustrations/unit_sizes/item.svg';

const PLAN_NAME_TO_ILLUSTRATION: Record<
  PlanName,
  { widthPct: number; src: string }
> = {
  Item: { widthPct: 50, src: item },
  '5x5': { widthPct: 30, src: fiveByFive },
  '5x10': { widthPct: 45, src: fiveByTen },
  '10x10': { widthPct: 55, src: tenByTen },
  '10x15': { widthPct: 65, src: tenByFifteen },
  '10x20': { widthPct: 70, src: tenByTwenty },
  '10x25': { widthPct: 75, src: tenByTwentyFive },
  '10x30': { widthPct: 80, src: tenByThirty },
  '10x40': { widthPct: 85, src: tenByForty },
  '10x50': { widthPct: 100, src: tenByFifty },
};

const Image = styled(animated.img)`
  ${mq({
    maxWidth: ['332px', '432px', 'unset'],
  })}
`;

export const PlanIllustrations = () => {
  const {
    flowState: {
      values: { planSize },
    },
  } = useStorageCheckoutContext();
  const isDesktop = useBreakpoint(BREAKPOINTS.LG_INT);
  const transitions = useTransition(planSize, planSize || '', {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: planSize ? 1 : 0.3 },
    leave: { opacity: 0 },
  });

  const multiplier = isDesktop ? 1 : 1.1;

  return (
    <Box.Flex
      justifyContent="center"
      alignItems="flex-end"
      height="100%"
      maxWidth={['500px', null, '572px']}
      position={[null, 'relative']}
      margin="0 auto"
    >
      {transitions.map(({ props, item }) => {
        // useTransition coerces an undefined `planSize` to `null` which prevents using a default value intializer
        const resolvedPlan = item ?? '5x5';
        return (
          <Image
            key={item}
            src={PLAN_NAME_TO_ILLUSTRATION[resolvedPlan].src}
            style={{
              ...props,
              width: `${
                multiplier * PLAN_NAME_TO_ILLUSTRATION[resolvedPlan].widthPct
              }%`,
            }}
            alt={item}
          />
        );
      })}
    </Box.Flex>
  );
};
