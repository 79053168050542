import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';

import styled from '@emotion/styled';

import * as Sentry from '@sentry/browser';

import {
  BREAKPOINTS,
  Box,
  COLORS,
  EXTRA_SMALL_TITLE_STYLES,
  FontWeight,
  SMALL_TITLE_STYLES,
  SkeletonLoader,
  Text,
  TextButton,
  mq,
} from '@clutter/clean';

import {
  AddressFragment,
  PlanFragment,
  RateGroupFragment,
  RateGroupKindEnum,
} from '@graphql/platform';

import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { clearStoragePlan } from '@root/components/checkout/helpers/moving';
import { PlanEstimatorModal } from '@root/components/checkout/product_pages/subcomponents/plan_estimator_modal';
import { RadioCard } from '@root/components/checkout/product_pages/subcomponents/radio_card';
import { SkipButton } from '@root/components/checkout/product_pages/subcomponents/skip_button';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import {
  DIMENSIONAL_PLANS,
  ITEM_PLAN,
  PLANS,
  Plan,
} from '@root/constants/pricing';
import { useTrack } from '@root/initializers/wt';
import { PlanName } from '@root/resources/types/plan_name';
import { EventSchema } from '@root/resources/wt/types';

import { Currency } from '@shared/currency';
import { Fallback } from '@shared/fallback';

import { useStoragePriceCompletedFunnelEvent } from '@utils/hooks/funnel_events/use_storage_price_completed_funnel_event';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { usePricingSetForZIP } from '@utils/hooks/pricing';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { useLastDefined } from '@utils/hooks/use_last_defined';

import mostPopular from '@images/illustrations/most_popular.svg';
import phoneInventory from '@images/illustrations/phone_inventory.svg';

import { GridTooltip } from './plan_size/grid_tooltip';

const MONTHLY_SUFFIX = '/mo';

type PlanSummary = {
  plan: PlanFragment;
  amount: number;
  rateGroup: RateGroupFragment;
};

const MostPopular = styled.img`
  position: absolute;
  right: -8px;
  top: -8px;
  width: 44px;

  ${mq({
    width: ['44px', null, '50px'],
    right: ['-8px', null, '-12px'],
    top: ['-10px', null, '-12px'],
  })}
`;

const PlanCards = styled.div`
  display: grid;

  ${mq({
    marginTop: ['32px', '24px'],
    gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr'],
    gridGap: ['12px', '16px'],
  })}
`;

const Card = styled(RadioCard)`
  &:last-of-type {
    grid-column-end: span 2;
  }

  @media (min-width: ${BREAKPOINTS.SM}) {
    grid-column-end: span 1 !important;
  }
`;

export const DESKTOP_PLAN_SELECTOR_SIZE = '356px'; // card height * 3 + gap * 2

let hasScrolled = false;

type PlanSizeStepProps = StorageCheckoutStepProps & {
  movingCheckoutFlow: boolean;
  movingExtendedDestinationServiceArea?: boolean;
  endAddress?: Partial<AddressFragment>;
};

const PlanPriceContainer: FC<{
  children: ReactNode;
}> = (props) => (
  <Text.Callout
    {...props}
    color={COLORS.tealPrimary}
    weight={FontWeight.Medium}
  />
);

const PlanPrice: FC<{
  map?: Record<string, { amount: number }>;
  plan: Plan;
}> = ({ plan, map }) => (
  <PlanPriceContainer>
    <Fallback
      delayMs={0}
      placeholder={<Box height="19px" />}
      loader={<SkeletonLoader height="19px" width="90px" margin="0 auto" />}
      value={map?.[plan.name]}
    >
      {map?.[plan.name] && `$${map?.[plan.name]?.amount}${MONTHLY_SUFFIX}`}
    </Fallback>
  </PlanPriceContainer>
);

export const PlanSize: React.FC<PlanSizeStepProps> = ({
  onChange,
  scrollToStep,
  values: {
    planSize,
    zip,
    dateScheduled,
    commitment = RateGroupKindEnum.Saver,
    skipStoragePlanSizeSelected,
    currentStorageCustomer,
  },
  movingCheckoutFlow,
  movingExtendedDestinationServiceArea,
  endAddress,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [recommendedPlan, setRecommendedPlan] = useState<PlanName | null>(null);
  const { isMobile } = useBreakpoints();

  const track = useTrack({ action: 'click' });

  const totalColumns = isMobile ? 2 : 3;
  const planIndex = recommendedPlan
    ? Object.keys(PLANS).indexOf(recommendedPlan)
    : -1;
  const tooltipRow = Math.floor(planIndex / totalColumns) + 1;
  const columnIndex = planIndex % totalColumns;

  const trackFunnelEvent = useTrackFunnelEvents();
  const trackStoragePriceCompleted = useStoragePriceCompletedFunnelEvent();

  const handleChange = (
    size: PlanName,
    amount?: number,
    preventScroll?: boolean,
  ) => {
    if (amount) {
      trackStoragePriceCompleted({
        planSize: size,
        commitment,
        amount,
      });
    } else {
      Sentry.captureMessage(
        `Selected plan ${size} without prices`,
        Sentry.Severity.Warning,
      );
    }
    if (!hasScrolled && !preventScroll) {
      hasScrolled = true;
      scrollToStep('next');
    }

    onChange('planSize', size);
    onChange('commitment', commitment); // Commitment selection should be defaulted after selecting a plan
    onChange('skipStoragePlanSizeSelected', false);

    // Changing plan size potentially impacts availability so we proactively reset.
    if (dateScheduled) onChange('dateScheduled', undefined);
  };

  const {
    pricingSummary: summary,
    defaultPricingSummary,
    subjobPackageEligible,
  } = useSharedCheckoutContext();
  const pricingSummary = useLastDefined(summary);
  const pricingSummaryLoading = pricingSummary
    ? !summary
    : !defaultPricingSummary || !!planSize;

  const storagePricingGroupEntries = usePricingSetForZIP(
    endAddress?.zip || zip,
  )?.storagePricingGroupEntries;

  const planMap = storagePricingGroupEntries
    ?.filter((pge) => pge.rateGroup?.name === commitment)
    ?.reduce<Record<string, PlanSummary>>((acc, pge) => {
      acc[pge.pricing.plan.name] = {
        plan: pge.pricing.plan,
        amount: pge.pricing.amount,
        rateGroup: pge.rateGroup!,
      };
      return acc;
    }, {});

  useEffect(() => {
    storagePricingGroupEntries &&
      storagePricingGroupEntries.length > 0 &&
      trackFunnelEvent({
        schema: EventSchema.WWW__StoragePriceViewed,
        action: 'display',
        metadata: {},
      });
  }, [storagePricingGroupEntries, trackFunnelEvent]);

  const onSkipStoragePlanSize = () => {
    clearStoragePlan(onChange);
    scrollToStep();
  };

  const visible =
    !movingCheckoutFlow ||
    (movingCheckoutFlow &&
      !currentStorageCustomer &&
      !movingExtendedDestinationServiceArea);

  useEffect(() => {
    onChange('skipStoragePlanSizeSelected', !visible);
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Box margin={movingCheckoutFlow ? ['48px 0 0', null, '72px 0 0'] : []}>
      <Box.Grid
        gridTemplateColumns={
          movingCheckoutFlow
            ? ['1fr', '1fr', '1fr', '3fr 1fr']
            : ['1fr', '2fr 1fr']
        }
      >
        <Box.GridItem>
          <Text.Title size="small" color={COLORS.tealDark}>
            {movingCheckoutFlow
              ? 'Need storage? First month free'
              : 'Select storage size'}
          </Text.Title>
        </Box.GridItem>
        <Box.GridItem
          textAlign={['left', 'left', 'left', 'right']}
          style={{ alignSelf: 'center' }}
        >
          <TextButton
            onClick={() => {
              setShowModal(true);
              track({
                objectName: 'plan_size_selector',
                label: 'Help me pick a size',
                objectType: 'button',
              });
            }}
          >
            Help me pick a size
          </TextButton>
        </Box.GridItem>
      </Box.Grid>
      {subjobPackageEligible && (
        <Box margin="4px 0 0">
          <Text.Body color={COLORS.storm}>
            We’ll lower your monthly rate if you end up needing less space.
          </Text.Body>
        </Box>
      )}
      {movingCheckoutFlow && (
        <Box margin="4px 0 0">
          <Text.Body color={COLORS.storm}>
            {endAddress?.city
              ? `Whatever you don’t need at your new place, we’ll store at our facility closest to ${endAddress.city}. `
              : 'We’ll store anything you don’t need in your new place. '}
            Your plan size will be automatically downgraded if you end up
            needing less storage space.
          </Text.Body>
        </Box>
      )}
      <PlanCards>
        {recommendedPlan && (
          <Box.GridItem gridColumn={['span 2', 'span 3']} gridRow={tooltipRow}>
            <GridTooltip columnIndex={columnIndex}>
              <Box.Flex justifyContent="space-between">
                <Text.Title size="extraSmall">
                  We recommend a {PLANS[recommendedPlan].label} unit
                </Text.Title>
                <TextButton onClick={() => setRecommendedPlan(null)}>
                  <span style={{ color: COLORS.cloud }}>Close</span>
                </TextButton>
              </Box.Flex>
              <Text.Body>
                This is based on your selections. Don’t worry, we can easily
                upgrade or downgrade your plan when we arrive!
              </Text.Body>
            </GridTooltip>
          </Box.GridItem>
        )}
        {DIMENSIONAL_PLANS.map((plan) => (
          <Card
            key={plan.name}
            selected={plan.name === planSize}
            onChange={() => {
              const planSummary = planMap?.[plan.name];
              handleChange(plan.name, planSummary && planSummary.amount);
            }}
            value={plan.name}
            name="planSize"
          >
            <Box.Flex
              textAlign="center"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Text
                style={
                  isMobile ? EXTRA_SMALL_TITLE_STYLES.SM : SMALL_TITLE_STYLES.SM
                }
              >
                {plan.label}
              </Text>
              <Text.Callout color={COLORS.hippo}>
                {plan.description}
              </Text.Callout>
              <Box margin="4px 0 0 ">
                <PlanPrice map={planMap} plan={plan} />
              </Box>
              {plan.name === '10x10' && <MostPopular src={mostPopular} />}
            </Box.Flex>
          </Card>
        ))}
        <Box.GridItem gridColumn={['span 2', 'span 3']}>
          <RadioCard
            selected={planSize === ITEM_PLAN.name}
            value={ITEM_PLAN.name}
            onChange={() => {
              const planSummary = planMap?.[ITEM_PLAN.name];
              handleChange(ITEM_PLAN.name, planSummary && planSummary.amount);
            }}
          >
            <Box.Flex
              flexDirection="column"
              gap="8px"
              alignItems="center"
              justifyContent="center"
            >
              <Text.Title size="extraSmall">Pay Per Box</Text.Title>
              <Text.Callout color={COLORS.hippo}>
                {ITEM_PLAN.description}
              </Text.Callout>
              <PlanPrice map={planMap} plan={ITEM_PLAN} />
            </Box.Flex>
          </RadioCard>
        </Box.GridItem>
      </PlanCards>

      {subjobPackageEligible && (
        <Box.Flex
          background={COLORS.dust}
          padding="16px"
          margin={['32px 0 0', '24px 0 0']}
          gap="16px"
        >
          <Box.FlexItem alignSelf="center" width="64px" flexShrink={0}>
            <img
              width="64"
              src={phoneInventory}
              alt="A phone displaying digital inventory"
            />
          </Box.FlexItem>
          <Box.FlexItem>
            <Text.Callout weight={FontWeight.Medium}>
              {planSize ? PLANS[planSize].label : 'Storage'} Unit
            </Text.Callout>
            <Text.Callout>
              Your Smart Storage space is temperature controlled, has 24/7
              security, and comes with an online photo inventory.
            </Text.Callout>
          </Box.FlexItem>
          <Box.FlexItem flexShrink={0}>
            <Fallback
              delayMs={0}
              loader={
                <SkeletonLoader
                  height={['20px', null, null, '38px']}
                  width="100px"
                  margin={['initial', null, null, '0 0 0 12px']}
                />
              }
              value={!pricingSummaryLoading}
            >
              <Box.FlexItem
                padding="2px 4px"
                borderRadius="4px"
                display="inline-block"
                order={[2, null, null, 'initial']}
              >
                {pricingSummary ? (
                  <Text.Body weight={FontWeight.Medium}>
                    {pricingSummary.monthlyAmount ? (
                      <Currency
                        amount={pricingSummary.monthlyAmount}
                        suffix={MONTHLY_SUFFIX}
                      />
                    ) : (
                      'Free'
                    )}
                  </Text.Body>
                ) : (
                  <>
                    <Text.Callout>Starting at </Text.Callout>
                    <Text.Body weight={FontWeight.Medium}>
                      <Currency
                        amount={defaultPricingSummary?.monthlyAmount || 0}
                        suffix={MONTHLY_SUFFIX}
                      />
                    </Text.Body>
                  </>
                )}
              </Box.FlexItem>
            </Fallback>
          </Box.FlexItem>
        </Box.Flex>
      )}
      {movingCheckoutFlow && (
        <SkipButton
          buttonText="Skip, I don’t need storage"
          selected={!!skipStoragePlanSizeSelected}
          trackingObjectName="skip_storage_plan_size"
          name="skipStoragePlanSize"
          value="skipStoragePlanSize"
          onSkip={onSkipStoragePlanSize}
        />
      )}
      <PlanEstimatorModal
        isOpen={showModal}
        onClose={(plan) => {
          setShowModal(false);
          if (plan) {
            setRecommendedPlan(plan.name);
            track({
              action: 'display',
              objectType: 'tooltip',
              objectName: 'recommended_plan_size_tooltip',
              value: recommendedPlan,
            });
            const planSummary = planMap?.[plan.name];
            handleChange(plan.name, planSummary && planSummary.amount, true);
          }
        }}
      />
    </Box>
  );
};
