import {
  DIMENSIONAL_PLANS,
  LARGEST_DIMENSIONAL_PLAN,
} from '@root/constants/pricing';
import type { Plan } from '@root/constants/pricing';

/**
 * Suggests a plan that fits a given cuft (cubic footage).
 *
 * @param cuft
 * @returns Plan
 */
export const suggestedPlanForCUFT = (cuft: number): Plan =>
  DIMENSIONAL_PLANS.find((plan) => plan.cuft > cuft) ??
  LARGEST_DIMENSIONAL_PLAN;
