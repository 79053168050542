import { RateGroupKindEnum } from '@graphql/platform';

import { Commitment } from '@root/resources/types/commitment';
import { PlanName } from '@root/resources/types/plan_name';

export const SMALLEST_PLAN_SIZE = '5x5';
export const WASHINGTON_PRICING_REGIONS = ['Portland', 'Seattle'];

export type Plan = {
  name: PlanName;
  label: string;
  description: string;
  cuft: number;
};

export const ITEM_PLAN: Plan = {
  name: 'Item',
  label: 'Pay Per Box',
  description: 'Boxes under 35 lbs and not exceeding 3 feet in any dimension',
  cuft: 0,
} as const;

export const DIMENSIONAL_PLAN_100: Plan = {
  name: '5x5',
  label: '5’ × 5’',
  description: 'Small closet size',
  cuft: 100,
} as const;

export const DIMENSIONAL_PLAN_200: Plan = {
  name: '5x10',
  label: '5’ × 10’',
  description: 'Walk-in closet size',
  cuft: 200,
} as const;

export const DIMENSIONAL_PLAN_400: Plan = {
  name: '10x10',
  label: '10’ × 10’',
  description: 'Garage size',
  cuft: 400,
} as const;

export const DIMENSIONAL_PLAN_600: Plan = {
  name: '10x15',
  label: '10’ × 15’',
  description: 'Studio apartment',
  cuft: 600,
} as const;

export const DIMENSIONAL_PLAN_800: Plan = {
  name: '10x20',
  label: '10’ × 20’',
  description: '1 bedroom home',
  cuft: 800,
} as const;

export const DIMENSIONAL_PLAN_1000: Plan = {
  name: '10x25',
  label: '10’ × 25’',
  description: '2 bedroom home',
  cuft: 1000,
} as const;

export const DIMENSIONAL_PLAN_1200: Plan = {
  name: '10x30',
  label: '10’ × 30’',
  description: '3 bedroom home',
  cuft: 1200,
} as const;

export const DIMENSIONAL_PLAN_1600: Plan = {
  name: '10x40',
  label: '10’ × 40’',
  description: '4 bedroom home',
  cuft: 1600,
} as const;

export const DIMENSIONAL_PLAN_2000: Plan = {
  name: '10x50',
  label: '10’ × 50’',
  description: '5 bedroom home',
  cuft: 2000,
} as const;

export const LARGEST_DIMENSIONAL_PLAN = DIMENSIONAL_PLAN_2000;

export const PLANS: Record<PlanName, Plan> = {
  Item: ITEM_PLAN,
  '5x5': DIMENSIONAL_PLAN_100,
  '5x10': DIMENSIONAL_PLAN_200,
  '10x10': DIMENSIONAL_PLAN_400,
  '10x15': DIMENSIONAL_PLAN_600,
  '10x20': DIMENSIONAL_PLAN_800,
  '10x25': DIMENSIONAL_PLAN_1000,
  '10x30': DIMENSIONAL_PLAN_1200,
  '10x40': DIMENSIONAL_PLAN_1600,
  '10x50': DIMENSIONAL_PLAN_2000,
} as const;

export const plan = (slug: PlanName): Plan => PLANS[slug];

export const DIMENSIONAL_PLANS = [
  DIMENSIONAL_PLAN_100,
  DIMENSIONAL_PLAN_200,
  DIMENSIONAL_PLAN_400,
  DIMENSIONAL_PLAN_600,
  DIMENSIONAL_PLAN_800,
  DIMENSIONAL_PLAN_1000,
  DIMENSIONAL_PLAN_1200,
  DIMENSIONAL_PLAN_1600,
  DIMENSIONAL_PLAN_2000,
] as const;

export const COMMITMENT_TO_LENGTH: Record<Commitment, number> = {
  [RateGroupKindEnum.Mover]: 1,
  [RateGroupKindEnum.Flexer]: 4,
  [RateGroupKindEnum.Saver]: 8,
};

export const RATE_GROUP_COMMITMENT: PartialRecord<
  RateGroupKindEnum,
  Commitment
> = {
  [RateGroupKindEnum.CurbsideMover]: RateGroupKindEnum.Mover,
  [RateGroupKindEnum.CurbsideFlexer]: RateGroupKindEnum.Flexer,
  [RateGroupKindEnum.CurbsideSaver]: RateGroupKindEnum.Saver,
  [RateGroupKindEnum.Mover]: RateGroupKindEnum.Mover,
  [RateGroupKindEnum.Flexer]: RateGroupKindEnum.Flexer,
  [RateGroupKindEnum.Saver]: RateGroupKindEnum.Saver,
};
