import { useEffect } from 'react';

import {
  AppointmentQuote,
  LaborRate,
  Maybe,
  PackageSetEntryFragment,
  PricingSetFragment,
  Pricing__QuotableEnum,
  Pricing__SourceEnum,
  useAppointmentQuoteCreateMutation,
} from '@graphql/platform';

import { PLANS } from '@root/constants/pricing';
import { WT_VISITOR_TOKEN } from '@root/initializers/wt';
import { PlanName } from '@root/resources/types/plan_name';

import { extractRateGroup } from '@utils/pricing/pricing_set';

export type AppointmentQuoteCreateResult = {
  laborRate?: LaborRate;
  onboardingPackageSetEntry?: PackageSetEntryFragment;
  finalPackageSetEntry?: PackageSetEntryFragment;
  packageSetEntries: PackageSetEntryFragment[];
  appointmentQuote?: Maybe<AppointmentQuote>;
};

// This hook will automatically execute the mutation when its inputs change.
export const useAppointmentQuoteCreate = ({
  pricingSet,
  zip,
  rateGroupName,
  planSize,
  customerToken,
}: {
  pricingSet?: PricingSetFragment;
  zip?: string;
  rateGroupName?: string;
  planSize?: PlanName;
  customerToken?: string;
}): { quote: AppointmentQuoteCreateResult; loading: boolean } => {
  const rateGroup = extractRateGroup(rateGroupName, pricingSet);
  const cuft = planSize ? PLANS[planSize as PlanName]?.cuft : undefined;
  const quotableId = customerToken ? customerToken : WT_VISITOR_TOKEN;
  const quotableType = customerToken
    ? Pricing__QuotableEnum.Customer
    : Pricing__QuotableEnum.Visitor;
  const source = customerToken
    ? Pricing__SourceEnum.LandingReonboarding
    : Pricing__SourceEnum.Landing;

  const [appointmentQuoteCreate, result] = useAppointmentQuoteCreateMutation({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (cuft === undefined) return;
    if (rateGroup === undefined) return;
    if (zip === undefined) return;
    appointmentQuoteCreate({
      variables: {
        zip: zip,
        cuft: cuft,
        rateGroupId: rateGroup.id,
        quotableId: quotableId,
        quotableType: quotableType,
        source: source,
      },
    });
  }, [rateGroup, zip, cuft, quotableId, quotableType, source]);

  const data = result?.data?.appointmentQuoteCreate;
  if (!data) {
    return { quote: { packageSetEntries: [] }, loading: true };
  }
  const {
    laborRate,
    onboardingPackageSetEntries,
    finalPackageSetEntries,
    quote,
  } = data;
  const onboardingPackageSetEntry = onboardingPackageSetEntries[0];
  const finalPackageSetEntry = finalPackageSetEntries[0];
  const packageSetEntries = onboardingPackageSetEntries.concat(
    finalPackageSetEntries,
  );
  return {
    quote: {
      laborRate,
      appointmentQuote: quote,
      onboardingPackageSetEntry,
      finalPackageSetEntry,
      packageSetEntries,
    },
    loading: false,
  };
};
